import $ from 'jquery';

export function initCollapsible (blockSelector=".collapsible") {
    const block = $(blockSelector);
    

    block.each((i, element) => {
        let icon = 'plus';
        let blockH = $(element).parent();
        let current = blockH.next()
        let target = blockH.nextUntil(".block-Encabezado, .block-heading, .block-accordion, .block-title");
        const head = $(element);

        if (!$(head).hasClass("open")) {
            target.each((i, element) => {
              $(element).hide() ;
            });
        }else{
            icon = 'minus';
        }
        const link = $(`
            <a class="">
                <i class="fas fa-${icon}-circle"></i>
            </a>`);
        link.append(head.children().first());
        link.on("click", (event, element) => {
            $(target).slideToggle();
            link.children().first().toggleClass("fa-plus-circle fa-minus-circle");
        })
        .prependTo(head);
    });     

}
