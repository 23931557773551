import $ from 'jquery';

export function initMenuLink (linkSelector=".level_1.link") {
    const levelLink = $(linkSelector);
    const $ul = $("<ul/>", {class :'column level_1 link'});
    levelLink.each((i, element) => {
        const $ul = $("<ul/>", {class :'column level_1 link is-flex is-justify-content-flex-end'});
        const parentLink = $(element).closest('.navbar-dropdown').find('.media-content');
        //const parentLink = $(element).closest('.navbar-dropdown').find('.brand');
        //parentLink.append(levelLink);
        $ul.append($(element).find("a"));
        parentLink.append($ul);
    });
    levelLink.remove();
}
