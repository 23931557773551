import $ from 'jquery';

const showToolTip = (element) => {
    const el = $(element);
    const classes = ['tooltip', 'is-tooltip-success'];
    el.attr('data-tooltip', 'Vínculo copiado al portapapeles.');
    $(el).toggleClass(classes);
    setTimeout(() => el.removeClass(classes), 2000);
}

export const copyToClipboard = (event) => {
    event.preventDefault();
    const content = document.createElement('textarea');
    const target = event.currentTarget;
    content.value = target.href;
    // Set non-editable to avoid focus and move outside of view
    content.setAttribute('readonly', '');
    content.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(content);
    // Select text inside element
    content.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(content);
    showToolTip(target);

}