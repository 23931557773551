import $ from 'jquery';

const show_subject = (event) => {
    event.preventDefault();
    const subjectSelected = $(event.currentTarget).parent();
    $( ".modal-card-body").empty().append(subjectSelected.clone());
    $(".course_plan .subject .selected").removeClass("selected");
    subjectSelected.addClass("selected");
    $(".modal").addClass("is-active").removeClass('is-inactive'); 
}

export function InitCoursePlanNav(blockSelector = ".carrera"){
    const block = $(blockSelector);
    if (block.length){

        $('.load-page').on('click', show_subject);
        
        $(".modal-close-window").on("click", (event) => {
          event.preventDefault();
          $(".modal.is-active").removeClass("is-active").addClass('is-inactive');
          $(".subject.selected").removeClass("selected");
        });
        
        $(document).keydown((event) => {
          if (event.keyCode == 37 && ! event.altKey ) {
            $(".nav-left").click();
            return false;
          }
          if (event.keyCode == 39 && ! event.altKey ) {
            $(".nav-right").click();
            return false;
          }
          if (event.keyCode == 27 && ! event.altKey ) {
            $(".modal-close-window").click();
            return false;
          }
        });

        // mover a subjet
        $(".carrera .modal .chevron").on("click", (event) => {
          const match = $(".course_plan .subject");
          const oldSel = match.filter(".selected");
          let i = match.index(oldSel);
          const button = $(event.currentTarget);
          if (button.hasClass("nav-right")) {
            i++;
          } else {
            i--;
          }
          const subjectSelected = match.filter(match[i]);
          $(".modal-card-body").empty()
            .append(subjectSelected.clone());
          oldSel.removeClass("selected");
          subjectSelected.addClass("selected");
        
        });



    }
}

