import $ from 'jquery';
import Glide from '@glidejs/glide';

const displayParticle = ()=> {
  const elem = $('.glide__slide--active .particle_field');
  // si no tiene particle-1 crea las siete particulas
  const elImg = elem.find("img");
  
  if (!(elImg.length > 1)) {
    for (var i = 2; i < 8; i++) {
      elImg.clone().removeClass("particle-1").addClass("particle-" + i ).appendTo(elem);
    }
  }
  $('.glide__slide--active .particle_field img').hide().each(function (index) {
    $(this).delay(700 * index).fadeIn(600);
  })
}

export const initGlide = (selector) => {
  const glideOptions = {
    type: 'carousel',
    gap: 0,
    autoplay: 8000,
    animationDuration: 800,
    hoverpause: true,
    circular: false,
    transitionType: 'fade',
    animationDuration: 800,
    disabledArrow: 'glide__arrow--disabled',
    navigation: true,
  };
  if ($(selector).length) {
    const slide = new Glide(selector, glideOptions);
    slide.on('build.after', () => {
      $(selector).animate({
        opacity: 1 });
    });

    slide.on(['mount.after', 'run.after'], displayParticle);
    slide.on(['run.before'], () => {
      $('.glide__slide--active .particle_field img').hide();
    })
    slide.mount();
  }
}
