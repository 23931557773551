import $ from 'jquery';

const view = $(window).width();

if (view > 1087) {

    $('.search-form').on('mouseover', ()=> {
        $('.search-input').show();
        $('#search-control').addClass('is-form-active');
        $('#search-control form').addClass('is-form-active');
    });
    
    $('.search-form').on('focusout', ()=>{
        $('.search-input').hide();
        $('#search-control').removeClass('is-form-active');
        $('#search-control form').removeClass('is-form-active');
    });
}