import $ from 'jquery';

export function initEventClock (blockSelector=".timer" ) {
    const block = $(blockSelector);
    if (block.length) {

        block.each((i, element) => {
            const time = new Date($(element).find("time").attr("datetime"));
            const days = Math.round((time.getTime() - Date.now()) / 86400000);
            const degree = 360 - ((360 / 31) * days);
            const point = `
                  <div class="radius" style="transform: rotate(${degree}deg)">
                    <div class="point tooltip is-tooltip-top" data-tooltip="Faltan ${days} días" style="transform: rotate(${degree*-1}deg)"></div>
                  </div>`;
                $(point).appendTo(element);
        });
        
    }    
}