import $ from 'jquery';

// Menu hamburguesa
var $navbarBurger = $('.navbar-burger');
var $menu = $('.navbar-menu');

$navbarBurger.click(function() {
  $(this).toggleClass('is-active');
  $menu.toggleClass('is-active');
});
