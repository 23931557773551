import $ from 'jquery';
import Glide from '@glidejs/glide';

export const initGallery = (selector) => {
    const galleries = $(selector);
    if (galleries.length) {

        const glideOptions = {
            type: 'carousel',
            gap: 0,
            autoplay: false,
            animationDuration: 800,
            hoverpause: true,
            circular: false,
            transitionType: 'fade',
            animationDuration: 800,
            disabledArrow: 'glide__arrow--disabled',
            navigation: true,
        };

        galleries.each((i, element) => {
            let item = $(element);
            let d = new Date();
            let id = 'gallery-' + d.getTime();
            item.attr('id', id);
            let gallery = new Glide('#' + id, glideOptions);
            gallery.on('build.after', () => {
                item.animate({
                    opacity: 1
                });
            });

            gallery.mount();
        });

        var $allVideos = $('.glide__slide'),
        // var $allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube.com']"),
            $fluidEl = $('.glide');

        $allVideos.each(function () {
            $(this)
                .data('aspectRatio', this.height / this.width)
                .removeAttr('height')
                .removeAttr('width');
        });

        $(window).resize(function () {
            var newWidth = $fluidEl.width();
            $allVideos.each(function () {
                var $el = $(this);
                $el
                    .width(newWidth)
                    .height(newWidth * $el.data('aspectRatio'));
            });
        });

        $(window).resize();



    }
}