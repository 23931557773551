import $ from 'jquery';
import Glide from '@glidejs/glide';

export const initAdvertisements = (blockSelector=".advertisements" ) => {

    const block = $(blockSelector);
    if (block.length) {
        const el = block.find(".media");
        if (el.length > 1){
            const glideOptions = {
                type: 'carousel',
                autoplay: 5000,
                perView: 1
            };
            
            const adv = new Glide(blockSelector, glideOptions);
            adv.mount();        
        }
    }    
}
