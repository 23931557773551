import $ from 'jquery';
import egresados from '../img/homemap/egresados.json';


const mercator = (x, y) => {
  return [x, Math.log(Math.tan(y / 2 + Math.PI / 4))];
}

const translate = (lat, long, x0, y0) => {

  /*
      lat: +- 0- 90 , long: +- 0 - 180;
      top:10%; left:50%;
  const x0 = box_width / 2;
  const y0 = box_height / 2;
  */
/*
  const left = (x0 - 20) + (long * (x0 / 180));
  const top =  (y0 + 20) - (lat * (y0 / 90));

  const left = (x0 + 0) + (long * (x0 / 180) * 1);
  const top =  (y0 + 0) - (lat * (y0 / 90) * 1);
*/
  const left = (45.5) + ((long * 100) / 180) * 0.51;
  const top =  (64) - ((lat * 100) / 90) * 0.54;

  return [top, left];

}

const marker_template = (element, i, w, h) => {
  const [long, lat] = element.geometry.coordinates;
  const [top, left] = translate(lat, long, w, h);

  const style = `top: ${top}%; left: ${left}%`;

  const out = `
    <li class="map-marker" data-coordinates="${long},${lat}" style="${style}">
        <i class="map-marker-center"></i>
        <i class="map-marker-area"></i>
        <div class="map-marker-info">
            <p class="map-marker-info-title">${element.properties.Name}</p>
            <p class="map-marker-info-content">${element.properties.description}</p>
        </div>
    </li>
    `
  return out

}

const place_graduated = (container) => {
  const markerContainer = container.find('.markers');
  const [w,h] = [container.find("svg").width()/2, container.find("svg").height()/2]
  const markers = egresados.features.map((e, i) => marker_template(e, i, w,h ));
  markers.forEach(marker => $(marker).appendTo(markerContainer));
      $(".map-marker").each(function(index) { 

        const that = this;

        $( that ).on({
          mouseenter: function() {
            $( this ).addClass( "hover" );
          }, mouseleave: function() {
            $( this ).removeClass( "hover" );
          }
        });
        var t = setTimeout(function() { 
            $( that ).parent().find(".active").removeClass("active");
            $(that).addClass("active"); 
        }, 4000 * index);        
    });

}

export function initHomeMap(blockSelector = ".map-viewer") {
  const block = $(blockSelector);
  const map_drawing = document.querySelector(`${blockSelector} svg`);
  const markers = place_graduated( block );
  return markers;
}