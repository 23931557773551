import $ from 'jquery';

const navbarHeight = $('.navbar').height();
const navbar = $('.navbar');
const container = $('#container');

$(document).ready(function() {
	$(window).scroll(function() {
		if($(window).scrollTop() > navbarHeight) {
      container.css('margin-top', navbarHeight);
		  navbar.addClass('is-fixed-top').removeClass('not-fixed-top');
		} else if($(window).scrollTop() < navbarHeight) {
      container.css('margin-top', 0);
		  navbar.addClass('not-fixed-top').removeClass('is-fixed-top');
		}
	});
});