function hideOnClickOutside(element, anchor) {
  const outsideClickListener = (event) => {
    event.stopPropagation()
    debugger
    if (!element.contains(event.target) && element.classList.contains("is-active")) {
      // or use: event.target.closest(selector) === null
      anchor.click();
      removeClickListener();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener("click", outsideClickListener);
  };

  document.addEventListener("click", outsideClickListener);
}

export const initDropdown = () => {
  const mainNavigation = document.querySelector("#main-navigation");

  const menuItems = mainNavigation.querySelectorAll(".navbar-link");
  menuItems.forEach((item) => {
    item.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation()
      const menuItem = event.currentTarget;
      const dropdown = mainNavigation.querySelector(menuItem.dataset.submenuId);
      const active = mainNavigation.querySelectorAll(".is-active");
      active.forEach((e) => {
        if (e != item && e != dropdown) {
          e.classList.remove("is-active");
        }
      });
      menuItem.classList.toggle("is-active");
      dropdown.classList.toggle("is-active");
      setTimeout(() => {
        hideOnClickOutside(dropdown, menuItem);
      }, 500);
    });
  });
};
