import $ from 'jquery';

const updateScrollSpy = (blocks, ul, height) => {

    if ($(window).scrollTop() > height) {
        ul.addClass('is-fixed-top');
    } else if ($(window).scrollTop() < height) {
        ul.removeClass('is-fixed-top');
    }
    ul.children().removeClass("is-active");
    blocks.each((i, element) => {
        let id = $(element).find("h2").attr("id");
        if( $(element).isOnScreen() ){
            const link=`#link-${id}`;
            $(link).addClass("is-active is-visited");
            return false;
        }
    })
}

export function initScrollSpy (blockSelector=".scroll-index",
        target=".sidebar", height=60 ) {
    const block = $(blockSelector);

    if (block.length ) {
        let textMenu = $(target);
        if ( textMenu.length < 1) {
            textMenu = $("#container>.columns")
                        .append("<div class='sidebar column is-one-quarter'/>")
                        .find(".sidebar");
        }

        const ul = $('<ul class="scrollspy"/>');
        ul.appendTo(textMenu);
        block.each((i, element) => {
            const id = $(element).find("h2").attr("id");
            const li = `
                <li id='link-${id}' class="steps-segment">
                    <span class="steps-marker"></span>
                    <a class="steps-content">
                        ${$(element).text().trim().replace(/^(.{20}).+/, "$1…")}
                    </a>
                </li>`;
            $(li).on("click", () => {
                    const anchor = `#${id}`;
                    $('html, body').animate({
                        scrollTop: $(anchor).offset().top - 150}, 500);
                        console.log("hola");
                })
                .appendTo(ul);
        });
        $(window).scroll(()=>{
            updateScrollSpy(block, ul, height);
        });

        $.fn.isOnScreen = function () {
            var win = $(window);
            var viewport = {
                top: win.scrollTop(),
                left: win.scrollLeft()
            };
            viewport.right = viewport.left + win.width();
            viewport.bottom = viewport.top + win.height();

            var bounds = this.offset();
            bounds.right = bounds.left + this.outerWidth();
            bounds.bottom = bounds.top + this.outerHeight();

            return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

        };



    }
}
