import css from "./scss/style.scss";
import $ from "jquery";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebookSquare,
  faFacebook,
  faFacebookF,
  faWhatsappSquare,
  faYoutube,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { initScrollSpy } from "./js/scrollspy";
import { InitCoursePlanNav } from "./js/show_subject";
import { initCollapsible } from "./js/collapsible";
import { initEventClock } from "./js/event-clock";
import { initAdvertisements } from "./js/advertisements";
import { initHomeMap } from "./js/homemap";
import { initGlide } from "./js/slide";
import { initSlide } from "./js/slider";

import { initGallery } from "./js/gallery";
import { copyToClipboard } from "./js/copyToClipboard";
import { initMenuLink } from "./js/level_link";
import { initDropdown } from "./js/dropdown";
import { initVideos, resizeVideos } from "./js/videos";
import { initForms, resizeForms } from "./js/form";

import "./js/burgers";
import "./js/sticky-menu";
import "./js/share";
import "./js/search_form";

import AOS from "aos";
import "aos/dist/aos.css";

library.add(
  fas,
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faWhatsappSquare,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faLinkedin,
);
dom.watch();

$(() => {
  initMenuLink();
  initScrollSpy();
  initCollapsible();
  initEventClock();
  initHomeMap();
  initDropdown();
  initVideos();
  resizeVideos();
  initForms();
  resizeForms();
  $(".social-share-link").on("click", copyToClipboard);

  InitCoursePlanNav();

  initGlide(".home .block-degrees .glide");
  initSlide(".block-slider .glide");
  initGallery(".gallery");

  initAdvertisements();

  AOS.init({
    duration: 600,
    anchorPlacement: "top-bottom",
  });

  //links externos en nueva ventana
  const externalLinkSelector = `a:not([href^='${window.location.host}']):not([href^="#"]):not([href^="/"]):not([href^="?"])`;
  $(externalLinkSelector).attr("target", "_blank").addClass("externalLink");
  //controla la visibilidad de la caja seccion eventos de home
  if ($(".home")) {
    if (!$("#home-events-section .column").length)
      $("#home-events-section").hide();
  }
  if ($(".oficina")) {
    if (!$(".box.events .card").length) $(".box.events").hide();
  }
});

window.addEventListener("resize", resizeVideos);
window.addEventListener("resize", resizeForms);
